<template>
  <div class="e_price_detail">
    <div class="center-between">
      <strong>{{ $t('room_rate') }}</strong>
      <span>{{ eOccPricing.totals.exclusive.request_currency.currency }} {{ eOccPricing.totals.exclusive.request_currency.value * roomCount }}</span>
    </div>
    <div class="center-between e_room_price" v-for="(np,index) in eOccPricing.nightly" v-bind:key="index">
      <span>{{ dayjs(checkIn).add(index, 'day').format('YYYY-MM-DD') }}</span>
      <span>{{ np.filter(item => item.type === 'base_rate')[0].currency }} {{ np.filter(item => item.type === 'base_rate')[0].value * roomCount }}</span>
    </div>
    <div class="center-between e_room_price">
      <span>{{ $t('surcharges') }}</span>
      <span>{{ eOccPricing.totals.exclusive.request_currency.currency }} {{ $filters.formatAmount(calRoomAdditionRate(eOccPricing) * roomCount, 2) }}</span>
    </div>
    <div class="center-between" v-if="calTaxFree(eOccPricing) > 0">
      <a-popover placement="left">
        <template #content>
          <div style="width: 250px;">
            <template v-if="isZhCn">
              该费用包括旅行服务供应商（即酒店、租车公司）和/或我们针对您的预订向税务部门支付的税款估算金额（包括但不限于销售、住宿和增值税）。该金额可能还包括我们支付的度假费、清洁费及其他费用和/或我们、酒店供应商和/或您预订的网站为我们和/或他们提供的服务所收取的费用，具体取决于地点、金额及预订方式等因素。有关更多详细信息，请参阅条款和条件。
            </template>
            <template v-else>
              This charge includes estimated amounts the travel service provider (i.e. hotel, car rental company) pays for their taxes, and/or taxes that we pay, to taxing authorities on your booking (including but not limited to sales, occupancy, and value added tax). This amount may also include any amounts charged to us for resort fees, cleaning fees, and other fees and/or a fee we, the hotel supplier and/or the website you booked on, retain as part of the compensation for our and/or their services, which varies based on factors such as location, the amount, and how you booked. For more details, please see the Terms and Conditions.
            </template>
          </div>
        </template>
        <strong>{{ $t('taxes_fees') }} <QuestionCircleOutlined /></strong>
      </a-popover>
      <span>{{ eOccPricing.totals.exclusive.request_currency.currency }} {{ $filters.formatAmount(calTaxFree(eOccPricing) * roomCount, 2) }}</span>
    </div>
    <div class="center-between" v-if="calSumPrice(eOccPricing, 'sales_tax') > 0">
      <strong>{{ $t('sales_tax') }}</strong>
      <span>{{ eOccPricing.totals.exclusive.request_currency.currency }} {{ $filters.formatAmount(calSumPrice(eOccPricing, 'sales_tax') * roomCount, 2) }}</span>
    </div>
    <div class="center-between" v-if="calSumPrice(eOccPricing, 'property_fee') > 0">
      <strong>{{ $t('property_fee') }}</strong>
      <span>{{ eOccPricing.totals.exclusive.request_currency.currency }} {{ $filters.formatAmount(calSumPrice(eOccPricing, 'property_fee') * roomCount, 2) }}</span>
    </div>
    <div class="center-between" v-if="calAdditionRate(eOccPricing) > 0">
      <strong>{{ $t('surcharges') }}</strong>
      <span>{{ eOccPricing.totals.exclusive.request_currency.currency }} {{ calAdditionRate(eOccPricing) * roomCount }}</span>
    </div>
    <a-divider style="margin: 5px 0px"/>
    <div class="center-between">
      <strong>{{ $t('above_total') }}</strong>
      <span>{{ eOccPricing.totals.inclusive.request_currency.currency }} {{ Decimal.add(eOccPricing.totals.property_fees?eOccPricing.totals.property_fees.request_currency.value:0, eOccPricing.totals.inclusive.request_currency.value) * roomCount }}</span>
    </div>
    <a-divider style="margin: 8px 0"/>
    <div class="center-between" v-if="Decimal.sub(totalPrice, eOccPricing.totals.inclusive.request_currency.value * roomCount) > 0">
      <strong>{{ $t('jidu_service_charge') }}</strong>
      <span>{{ eOccPricing.totals.inclusive.request_currency.currency }} {{ Decimal.sub(totalPrice, eOccPricing.totals.inclusive.request_currency.value * roomCount) }}</span>
    </div>
    <div class="center-between warning-color" v-if="!isOrderDetail">
      <strong>{{ $t('pay_now') }}</strong>
      <span>{{ currency }} {{ $filters.formatAmount(totalPrice, 0) }}</span>
    </div>
    <div class="center-between" v-if="eOccPricing.totals?.property_fees?.billable_currency?.value > 0">
      <strong>{{ $t('pay_hotel') }}</strong>
      <span>{{ eOccPricing.totals.property_fees.billable_currency.currency }} {{ eOccPricing.totals.property_fees.billable_currency.value * roomCount }}</span>
    </div>
    <div class="e_price_desc">
      <template v-if="isZhCn">
        * 酒店将以当地货币收取税费或者额外强制的费用。到店支付的税费是基于旅行时汇率计算得出的，可能会有所不同。18周岁以下儿童随行时，可能适用不一样的政策。
      </template>
      <template v-else>
        * Taxes and/or property-imposed fees of mandatory extras will be collected by the property, in the property's local currency. Taxes and/or fees due at the property are based on current exchange rates, which may vary at the time of travel. A different policy may be applied when traveling with children whose age is under 18 years old.
      </template>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import Decimal from "decimal.js";
import { QuestionCircleOutlined } from '@ant-design/icons-vue';

export default {
  name: "ERateDetail",
  components: { QuestionCircleOutlined },
  props: {
    eOccPricing: Object,
    roomCount: Number,
    checkIn: String,
    currency: String,
    totalPrice: Number,
    isOrderDetail: Boolean
  },
  data(){
    console.log(this.eOccPricing)
    return {
      dayjs,
      Decimal,
      isZhCn: this.$store.getters["setting/getLanguage"] === 'zh_CN',
    }
  },
  methods: {
    calRoomAdditionRate(eoccPricing){
      let sum = 0
      eoccPricing.nightly.forEach(nightly => {
        nightly.filter(item => item.type === 'base_rate').forEach(p => {
          sum += p.value
        })
      })

      return eoccPricing.totals.exclusive.request_currency.value - sum
    },
    calTaxFree(eoccPricing){
      let sum = 0
      eoccPricing.nightly.forEach(nightly => {
        nightly.filter(item => item.type === 'tax_and_service_fee' || item.type === 'recovery_charges_and_fees').forEach(p => {
          sum += p.value
        })
      })
      eoccPricing?.stay?.filter(item => item.type === 'tax_and_service_fee' || item.type === 'recovery_charges_and_fees').forEach(p => {
        sum += p.value
      })
      return sum
    },
    calSumPrice(eoccPricing, type){
      let sum = 0
      eoccPricing.nightly.forEach(nightly => {
        nightly.filter(item => item.type === type).forEach(p => {
          sum += p.value
        })
      })
      eoccPricing?.stay?.filter(item => item.type === type).forEach(p => {
        sum += p.value
      })
      return sum
    },
    calAdditionRate(eoccPricing){
      let d3 = Decimal.sum(eoccPricing.totals.exclusive.request_currency.value, this.calTaxFree(eoccPricing), this.calSumPrice(eoccPricing, 'sales_tax'), this.calSumPrice(eoccPricing, 'property_fee')).toPrecision(2)
      return Decimal.sub(eoccPricing.totals.inclusive.request_currency.value, d3)
    }
  }
}
</script>

<style scoped>
.e_price_detail {
  width: 280px;
  line-height: 25px;
}

.e_price_detail strong {
  font-weight: 500;
}

.e_price_detail .e_room_price {
  padding-left: 5px;
  font-size: 13px;
  line-height: 20px;
  color: #7d7c7c;
}

.e_price_detail .e_price_desc {
  font-size: 13px;
  line-height: 20px;
  margin: 5px 0;
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 5px 10px;
}
</style>